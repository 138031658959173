<template>
  <div>
    <div class="wrapper">
      <div id="content-page" class="content-page" :style="getLayoutStyles()">
        <div class="p-30px">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { socialvue } from "../config/pluginInit"
export default {
  name: "ContentLayout",
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    socialvue.mainIndex()
  },
  methods: {
    /**
     * getLayoutStyles
     */
    getLayoutStyles () {
      if (this.currentRouteName.includes("GideEvent")) {
        return `padding: 35px 15px 0!important;`
      }
      else {
        return ""
      }
    }
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>
